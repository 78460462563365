const timeMask = (value) => {
  const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  const seconds = [/[0-5]/, /[0-9]/];

  if (value.length > 5) {
    return [...hours, ':', ...minutes, ':', ...seconds];
  }

  if (value.length > 2) {
    return [...hours, ':', ...minutes];
  }

  return hours;
};

export default {
  from_separator: {
    label: 'Desde',
    type: String,
    separator: true,
    optional: true,
  },
  from: {
    label: 'Fecha',
    type: Date,
  },
  fromHour: {
    label: 'Hora (HH:mm:ss)',
    type: String,
    mask: timeMask,
  },
  to_separator: {
    label: 'Hasta',
    type: String,
    separator: true,
    optional: true,
  },
  to: {
    label: 'Fecha',
    type: Date,
  },
  toHour: {
    label: 'Hora (HH:mm:ss)',
    type: String,
    mask: timeMask,
  },
};
