export default {
  _id: {
    type: String,
    visibility: false
  },
  incidentId: {
    label: 'Incidente',
    type: String,
    visibility: false
  },
  user: {
    label: 'Usuario',
    type: String,
    visibility: false
  },
  corporation: {
    label: 'Corporación',
    type: String,
    visibility: false
  },
  closeIncident: {
    label: 'Cerrar incidente',
    type: Boolean,
  },
  description: {
    label: 'Descripción',
    type: String,
    textarea: true,
  },
};
