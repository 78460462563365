export default {
  _id: {
    type: String,
    visibility: false
  },
  deviceId: {
    label: 'Device ID',
    type: String,
    visibility: false
  },
  category: {
    label: 'Tipo de incidente',
    type: String,
    model: 'v2/incident-categories',
    item_value: 'uniqueId',
    autocomplete: false,
    avatarSrcField: 'image',
    onChange: (value, fields) => {
      if (!value) {
        fields.reason.value = '';
        return;
      }
      const item = fields.category.getOption();
      fields.reason.value = item.name;
    }
  },
  reason: {
    label: 'Tipo de incidente',
    type: String,
    visibility: false
  },
  description: {
    label: 'Descripción',
    type: String,
    textarea: true,
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false
  },
  city: {
    label: 'Municipio',
    type: String,
  },
  neighborhood: {
    label: 'Colonia',
    type: String,
  },
  address: {
    label: 'Dirección',
    type: String,
  }
};
