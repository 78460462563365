<template>
  <div class="map">
    <cameras-widget ref="camerasWidget"></cameras-widget>
    <div id="map" class="map" ref="map"></div>
    <config-window v-if="show_config" :groups="groupsArray"></config-window>
    <tables-window
      v-if="show_tables"
      :camerasStore="cameraStore"
      :lprsStore="lprsStore"
      :devicesStore="devicesStore"
      :sensorsStore="sensorsStore"
      :incidentsStore="incidentsStore"
    ></tables-window>
  </div>
</template>

<script>
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'underscore';
import axios from 'axios';
import ApiService from '@/util/api.service';
import Util from '@/util/';
import MapActions from '@/components/map/mapActionsController';
import RightPanel from '@/components/map/rightPanel';
import mapImages from '@/util/mapImages';
import MapToolBar from '@/components/map/mapToolBarController';
import Dialog from '@/components/dialog';
import Form from '@/components/form';
import createIncidentModel from '@/models/createIncident.model';
import razonamientoModel from '@/models/razonamiento.model';
import motivosCad from '@/data/motivosReporteCad.json';
import { getAddress } from '@/util/location.service';
import { alarmTypes } from '@/components/devicesReports/data'
import LeftPanel from '../components/map/leftPanel/index';
// import MapZoom from '../components/map/mapZoomController'
import MapType from '../components/map/mapTypeController';
import CameraWindow from '../components/map/cameraWindowController';
import CameraStore from '../components/map/store/cameraStore';
import LprStore from '../components/map/store/lprStore';
import IncidentStore from '../components/map/store/incidentStore';
import IncidentHeatMapStore from '../components/map/store/incidentHeatMapStore';
import SensorStore from '../components/map/store/sensorStore';
import DeviceStore from '../components/map/store/deviceStore';
import SensorAlert from '../assets/map/sounds/sensor_alert.wav';
import DeviceEventAudio from '../assets/map/sounds/device_event_alert.mp3';
import DeviceCamerasDownloads from '../components/DeviceCamerasDownloads.vue';
import DeviceLocks from '../components/DeviceLocks.vue';

const ol = window.ol;

export default {
  name: 'Home',
  components: {
    'config-window': () => import('@/components/map/ConfigWindow.vue'),
    'tables-window': () => import('@/components/map/TablesWindow.vue'),
    'cameras-widget': () => import('@/components/map/CamerasWidget.vue'),
  },
  data() {
    return {
      tab: null,
      map: null,
      loading: false,
      loader: null,
      positions: {},
      initZoom: 12,
      cameras: {},
      lprs: {},
      devices: {},
      incidents: {},
      sensors: {},
      geofences: {},
      selectedItem: null,
      items: [],
      messages: [],
      clusterDistance: 45,
      camerasShown: {},
      groups: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('alerts', ['getIncidentAlerts', 'getDeviceAlerts']),
    ...mapGetters('map', [
      'getCamerasFilters',
      'getLprsFilters',
      'getDevicesFilters',
      'getSensorsFilters',
      'getIncidentsFilters',
      'getMapCenter',
      'getMapZoom',
    ]),
    ...mapState('map', [
      'mapMode',
      'show_cameras',
      'show_lprs',
      'show_devices',
      'show_sensors',
      'show_incidents',
      'show_incidents_heat_map',
      'show_geofences',
      'show_config',
      'show_live_route',
      'show_tables',
    ]),
    groupsArray() {
      return _.toArray(this.groups);
    },
  },
  created() {
    window.VMA.mapComponent = this;
    this.loading = true;
    this.sourceCameras = new ol.source.Vector();
    this.cameraStore = new CameraStore(this, this.sourceCameras);

    this.sourceLprs = new ol.source.Vector();
    this.lprsStore = new LprStore(this, this.sourceLprs);

    this.sourceIncidents = new ol.source.Vector();
    this.incidentsStore = new IncidentStore(this, this.sourceIncidents);

    this.sourceIncidentsHeatMap = new ol.source.Vector();
    this.incidentsHeatMapStore = new IncidentHeatMapStore(this, this.sourceIncidentsHeatMap);

    this.sourceSensors = new ol.source.Vector();
    this.sensorsStore = new SensorStore(this, this.sourceSensors);

    this.liveRouteSource = new ol.source.Vector();
    this.sourceDevices = new ol.source.Vector();
    this.devicesStore = new DeviceStore(this, this.sourceDevices, this.liveRouteSource);
  },
  mounted() {
    this.$store.dispatch('map/setSelectedItem', undefined);
    this.audio = new Audio(SensorAlert);
    this.deviceEventAudio = new Audio(DeviceEventAudio)
    this.deviceEventAudio.loop = false
    this.map = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      }),
    });
    this.satelliteMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
      }),
    });
    this.satelliteMap.setVisible(false);
    this.darkmodeMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        // url: '//api.maptiler.com/maps/a881ff38-8ab2-49ee-baae-c8ac0a1ede8f/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
        url: '//api.maptiler.com/maps/6dea3d11-51b5-48e9-997b-ebba1fdfefe4/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06',
      }),
    });
    this.darkmodeMap.setVisible(this.mapMode === 'night');

    // ////////////// //
    // Cameras Layer  //
    // ////////////// //

    this.clusterSourceCameras = new ol.source.Cluster({
      distance: 45,
      source: this.sourceCameras,
    });

    this.camerasLayer = new ol.layer.Vector({
      source: this.clusterSourceCameras,
      style: (feature) => {
        const size = feature.get('features').length;
        if (feature.get('features').length === 1) {
          return feature.get('features')[0].getStyle();
        }
        return new window.ol.style.Style({
          image: new ol.style.Icon({
            src: mapImages.camera[this.mapMode].up,
          }),
          text: new window.ol.style.Text({
            text: size.toString(),
            scale: 1.1,
            offsetX: 3,
            offsetY: -6,
            fill: new window.ol.style.Fill({
              color: '#fff',
            }),
            stroke: new ol.style.Stroke({
              color: '0',
              width: 3,
            }),
          }),
        });
      },
    });

    // ////////////// //
    // LPRs Layer     //
    // ////////////// //
    this.clusterSourceLprs = new ol.source.Cluster({
      distance: 45,
      source: this.sourceLprs,
    });

    this.lprsLayer = new ol.layer.Vector({
      source: this.clusterSourceLprs,
      style: (feature) => {
        const size = feature.get('features').length;

        if (feature.get('features').length === 1) {
          // const item = (feature.get('features')[0]).get('record')
          return feature.get('features')[0].getStyle();
        }

        return new window.ol.style.Style({
          image: new ol.style.Icon({
            src: mapImages.lpr[this.mapMode].up,
          }),
          text: new window.ol.style.Text({
            text: size.toString(),
            scale: 1.1,
            offsetX: 4,
            offsetY: -6,
            fill: new window.ol.style.Fill({
              color: '#fff',
            }),
            stroke: new ol.style.Stroke({
              color: '0',
              width: 3,
            }),
          }),
        });
      },
    });

    // ////////////// //
    // Device Layer   //
    // ////////////// //

    // live routes
    this.liveRouteLayer = new ol.layer.Vector({
      source: this.liveRouteSource,
    });

    this.clusterSourceDevices = new ol.source.Cluster({
      distance: 45,
      source: this.sourceDevices,
    });

    this.devicesLayer = new ol.layer.Vector({
      source: this.clusterSourceDevices,
      style: (feature) => {
        const size = feature.get('features').length;
        const features = feature.get('features');
        if (feature.get('features').length === 1) {
          features[0].set('clustered', false);
          const lineRoute = this.devicesStore.liveRoutes.get(features[0].getId());
          if (lineRoute && !this.liveRouteSource.hasFeature(lineRoute)) {
            this.liveRouteSource.addFeature(lineRoute);
          }
          return feature.get('features')[0].getStyle();
        }

        features.forEach((element) => {
          const lineRoute = this.devicesStore.liveRoutes.get(element.getId());
          if (this.liveRouteSource.hasFeature(lineRoute)) {
            this.liveRouteSource.removeFeature(lineRoute);
          }
          element.set('clustered', true);
        });
        const item = features[0].get('record');
        let icon = mapImages.deviceUp;
        let scale = 1;
        if (item.categoryData?.imageGroup) {
          icon = (process.env.VUE_APP_IMAGES_PATH || '/api/v2/public/images/') + item.categoryData.imageGroup.split('/').pop();
          scale = 0.7;
        }
        return new window.ol.style.Style({
          image: new ol.style.Icon({
            src: icon,
            scale,
          }),
          text: new window.ol.style.Text({
            text: size.toString(),
            scale: 1.1,
            fill: new window.ol.style.Fill({
              color: '#fff',
            }),
            stroke: new ol.style.Stroke({
              color: '0',
              width: 3,
            }),
          }),
        });
      },
    });

    // ////////////// //
    // Incidents Layer//
    // ////////////// //
    this.clusterSourceIncidents = new ol.source.Cluster({
      distance: 45,
      source: this.sourceIncidents,
    });

    this.incidentsLayer = new ol.layer.Vector({
      source: this.clusterSourceIncidents,
      style: (feature) => {
        const size = feature.get('features').length;
        if (feature.get('features').length === 1) {
          return feature.get('features')[0].getStyle();
        }
        return new window.ol.style.Style({
          image: new ol.style.Icon({
            src: mapImages.incident.default,
          }),
          text: new window.ol.style.Text({
            text: size.toString(),
            scale: 1.1,
            fill: new window.ol.style.Fill({
              color: '#fff',
            }),
            stroke: new ol.style.Stroke({
              color: '0',
              width: 3,
            }),
          }),
        });
      },
    });

    // ////////////// //
    // sensor Layer//
    // ////////////// //
    this.clusterSourceSensor = new ol.source.Cluster({
      distance: 45,
      source: this.sourceSensors,
    });

    this.sensorLayer = new ol.layer.Vector({
      source: this.clusterSourceSensor,
      style: (feature) => {
        const size = feature.get('features').length;
        if (feature.get('features').length === 1) {
          return feature.get('features')[0].getStyle();
        }
        return new window.ol.style.Style({
          image: new ol.style.Icon({
            src: mapImages.sensor.up,
          }),
          text: new window.ol.style.Text({
            text: size.toString(),
            scale: 1.1,
            offsetY: -6,
            fill: new window.ol.style.Fill({
              color: '#fff',
            }),
            stroke: new ol.style.Stroke({
              color: '0',
              width: 3,
            }),
          }),
        });
      },
    });

    // Crear una capa de heatmap
    this.incidentsHeatMapLayer = new ol.layer.Heatmap({
      source: this.sourceIncidentsHeatMap,
      blur: 15,
      radius: 5
    });
    // Geofences Layer
    // this.sourceGenfences = new ol.source.Vector()

    // this.geofencesLayer = new window.ol.layer.Vector({
    //   source: this.sourceGenfences
    // })
    this.mapView = new ol.View({
      center:
        this.getMapCenter || ol.proj.fromLonLat([this.getUser.longitude, this.getUser.latitude]),
      zoom: this.getMapZoom || this.initZoom,
    });
    this.controls = {
      mapActions: new MapActions(),
      rightPanel: new RightPanel({
        execFunction: this.execFunction,
      }),
      mapToolBar: new MapToolBar({
        setPlace: this.setPlace,
        findItems: this.findItems,
        selectCamera: this.selectCamera,
        selectLpr: this.selectLpr,
        selectDevice: this.selectDevice,
        selectIncident: this.selectIncident,
        selectSensor: this.selectSensor,
        getCenter: this.getCenter,
      }),
      leftPanel: new LeftPanel({
        incidentsStore: this.incidentsStore,
        devicesStore: this.devicesStore,
        devicesCounter: this.devicesStore.devicesCounter,
        camerasCounter: this.cameraStore.camerasCounter,
        incidentCategoryCounter: this.incidentsStore.categoryCount,
      }),
      // mapZoom: new MapZoom({
      //   zoomIn: () => this.mapView.setZoom(this.mapView.getZoom() + 0.33333333),
      //   zoomOut: () => this.mapView.setZoom(this.mapView.getZoom() - 0.33333333)
      // }),
      mapType: new MapType({
        setRoadMap: () => {
          this.satelliteMap.setVisible(false);
        },
        setSatellite: () => {
          this.satelliteMap.setVisible(true);
        },
        setDarkMode: () => {
          this.$store.dispatch('map/setMapMode', 'night');
          this.darkmodeMap.setVisible(true);
          this.updateMarkersStyle();
        },
        setDayMode: () => {
          this.$store.dispatch('map/setMapMode', 'day');
          this.darkmodeMap.setVisible(false);
          this.updateMarkersStyle();
        },
      }),
    };
    this.controls.mapToolBar.component.$on('map-center', () => {
      this.setCenter(this.getUser.latitude, this.getUser.longitude, this.initZoom);
    });
    this.controls.rightPanel.component.$on('center-alert', (item) => {
      switch (item.typeOfItem) {
        case 'devices': {
          const device = this.devicesStore.markers.get(item.id)?.get('record');
          if (device) {
            this.selectDevice(device, true, true);
          } else {
            this.selectDevice(item, true, true);
          }
          break;
        }
        case 'cameras':
          this.selectCamera(item, true);
          break;
        case 'incidents':
          this.selectIncident(item, true);
          break;
        case 'sensors':
          this.selectSensor(item, true);
          break;
        case 'lprs':
          this.selectLpr(item, true);
          break;
        case 'hitMandamiento':
          this.selectHit(item, true);
          break;
        case 'hitLpr':
          this.selectHit(item, true);
          this.setCenter(item?.attributes?.latitude, item?.attributes?.longitude);
          break;
        case 'hitRoboVehiculo':
          this.selectHit(item, true);
          break;
        case 'sosIncident':
          this.selectSOSIncident(this.incidentsStore.getRecord(item.id), true);
          break;
        default:
          this.$store.dispatch('map/setSelectedItem', item);
      }
    });

    // var colorArray = ol.color.asArray(color).slice();

    // const styles = [
    //   new ol.style.Style({
    //     stroke: new ol.style.Stroke({
    //       color: '#ff0000',
    //       width: 3,
    //     }),
    //     fill: new ol.style.Fill({
    //       color: '#ff0000',
    //     }),
    //   })
    // ];

    const styleFunction = (feature) => {
      const item = this.geofences[feature.getId()];
      const style = new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: item.attributes && item.attributes.color ? item.attributes.color : 'blue',
          width: 3,
        }),
        fill: new ol.style.Fill({
          color: item.attributes && item.attributes.color ? item.attributes.color : 'blue',
        }),
      });
      const colorArray = ol.color.asArray(style.getFill().getColor()).slice();
      colorArray[3] = 0.35;
      style.getFill().setColor(colorArray);
      return style;
    };

    this.sourceGenfences = new ol.source.Vector({});

    this.geofencesLayer = new ol.layer.Vector({
      source: this.sourceGenfences,
      style: styleFunction,
    });

    this.sourceGenfencesPoints = new ol.source.Vector({});

    const styleColorGeofencePoint = (feature) => {
      const item = feature.get('record');
      return new ol.style.Style({
        image: new ol.style.Circle({
          radius: 6,
          stroke: new ol.style.Stroke({ color: '#fff' }),
          fill: new ol.style.Fill({ color: item.color || '#3399CC' }),
        }),
      });
    };

    this.geofencesPointsLayer = new ol.layer.Vector({
      source: this.sourceGenfencesPoints,
      style: styleColorGeofencePoint,
    });

    this.sourceGenfencesRoutes = new ol.source.Vector({});

    this.geofencesRoutesLayer = new ol.layer.Vector({
      source: this.sourceGenfencesRoutes,
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: '#3461eb',
          width: 3,
        }),
      }),
    });

    const styleFunctionDeviceGeofence = (feature) => {
      const item = feature.get('record');
      if (item.typeOfItem === 'geofencePoint') {
        return new ol.style.Style({
          image: new ol.style.Circle({
            radius: 6,
            stroke: new ol.style.Stroke({ color: '#fff' }),
            fill: new ol.style.Fill({ color: item.color || '#3399CC' }),
          }),
        });
      }
      const style = new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: item.attributes && item.attributes.color ? item.attributes.color : 'blue',
          width: 3,
        }),
        fill: new ol.style.Fill({
          color: item.attributes && item.attributes.color ? item.attributes.color : 'blue',
        }),
      });
      const colorArray = ol.color.asArray(style.getFill().getColor()).slice();
      colorArray[3] = 0.35;
      style.getFill().setColor(colorArray);
      return style;
    };

    this.sourceDeviceGeofences = new ol.source.Vector({});

    this.deviceGeofencesLayer = new ol.layer.Vector({
      source: this.sourceDeviceGeofences,
      style: styleFunctionDeviceGeofence,
    });

    // Load Layers Visibility
    this.updateFilters();
    this.liveRouteLayer.setVisible(this.show_live_route);
    this.incidentsHeatMapLayer.setVisible(this.show_incidents_heat_map);

    this.olmap = new ol.Map({
      target: 'map',
      controls: [
        this.controls.mapActions,
        this.controls.rightPanel,
        this.controls.mapToolBar,
        this.controls.leftPanel,
        // this.controls.mapZoom,
        this.controls.mapType,
      ],
      layers: [
        this.map,
        this.darkmodeMap,
        this.satelliteMap,
        this.incidentsHeatMapLayer,
        this.geofencesLayer,
        this.deviceGeofencesLayer,
        this.liveRouteLayer,
        this.camerasLayer,
        this.lprsLayer,
        this.devicesLayer,
        this.sensorLayer,
        this.incidentsLayer,
        this.geofencesRoutesLayer,
        this.geofencesPointsLayer,
      ],
      view: this.mapView,
    });
    this.olmap.getViewport().addEventListener('contextmenu', (e) => {
      if (this?.getUser?.attributes?.permission_murphy !== true) {
        return;
      }
      e.preventDefault();
      const [longitude, latitude] = ol.proj.toLonLat(this.olmap.getEventCoordinate(e));
      getAddress(latitude, longitude)
        .then((ret) => {
          const item = {
            city: ret.municipio || '',
            neighborhood: ret.colonia || '',
            address: ret.direccion || '',
            latitude,
            longitude,
          };
          const form = new Form({
            schema: createIncidentModel,
            item,
          });
          const dialog = new Dialog({
            title: 'Crear incidente',
            titleColor: 'secondary',
            actions: [
              {
                color: 'success',
                help: 'Guardar',
                icon: 'mdi-content-save',
                action: () => {
                  if (form.hasErrors()) {
                    return;
                  }
                  window.VMA.loading(true);
                  axios({
                    url: '/api/cadinc/create',
                    method: 'post',
                    data: form.getItem(),
                  })
                    .then(() => {
                      dialog.close();
                    })
                    .catch((err) => {
                      console.error({ err });
                      window.VMA.showError({ title: 'Ocurrió un error al crear el incidente' });
                    })
                    .finally(() => {
                      window.VMA.loading(false);
                    });
                },
              },
            ],
          });
          dialog.open();
          dialog.append(form);
        })
        .catch((error) => {
          console.error(error);
        });
    });
    this.olmap.on('moveend', () => {
      this.$store.state.map.mapCenter = this.mapView.getCenter();
      if (this.$store.state.map.mapZoom < 18 && this.mapView.getZoom() > 18) {
        this.clusterSourceLprs.setDistance(0);
        this.clusterSourceDevices.setDistance(0);
        this.clusterSourceIncidents.setDistance(0);
        this.clusterSourceSensor.setDistance(0);
        this.clusterSourceCameras.setDistance(0);
      }

      if (this.mapView.getZoom() < 18 && this.$store.state.map.mapZoom > 18) {
        this.clusterSourceLprs.setDistance(45);
        this.clusterSourceDevices.setDistance(45);
        this.clusterSourceIncidents.setDistance(45);
        this.clusterSourceSensor.setDistance(45);
        this.clusterSourceCameras.setDistance(45);
      }

      this.$store.state.map.mapZoom = this.mapView.getZoom();
    });
    this.olmap.on('pointermove', (e) => {
      if (e.dragging) {
        return;
      }
      const pixel = this.olmap.getEventPixel(e.originalEvent);
      const hit = this.olmap.hasFeatureAtPixel(pixel);
      this.olmap.getTargetElement().style.cursor = hit ? 'pointer' : '';
      if (hit) {
        this.olmap.forEachFeatureAtPixel(e.pixel, (feature) => {
          let feat = feature;
          if (feature && feature.get('features') && feature.get('features').length === 1) {
            feat = feature.get('features')[0];
            if (feat.getId()) {
              const data = feat.get('record');
              if (data) {
                switch (data.typeOfItem) {
                  case 'incidents':
                    this.incidentsStore.hoverMarker(data.id);
                    break;
                  case 'devices':
                    this.devicesStore.hoverMarker(data.id);
                    break;
                  case 'cameras':
                    this.cameraStore.hoverMarker(data.id);
                    break;
                  case 'lprs':
                    this.lprsStore.hoverMarker(data.id);
                    break;
                  case 'sensors':
                    this.sensorsStore.hoverMarker(data.id);
                    break;
                  default:
                    this.incidentsStore.hoverMarker(null);
                    this.lprsStore.hoverMarker(null);
                    this.cameraStore.hoverMarker(null);
                    this.devicesStore.hoverMarker(null);
                    this.sensorsStore.hoverMarker(null);
                    break;
                }
              } else {
                this.incidentsStore.hoverMarker(null);
                this.lprsStore.hoverMarker(null);
                this.cameraStore.hoverMarker(null);
                this.devicesStore.hoverMarker(null);
                this.sensorsStore.hoverMarker(null);
              }
            }
          } else {
            this.incidentsStore.hoverMarker(null);
            this.lprsStore.hoverMarker(null);
            this.cameraStore.hoverMarker(null);
            this.devicesStore.hoverMarker(null);
            this.sensorsStore.hoverMarker(null);
          }
        });
      } else {
        this.incidentsStore.hoverMarker(null);
        this.lprsStore.hoverMarker(null);
        this.cameraStore.hoverMarker(null);
        this.devicesStore.hoverMarker(null);
        this.sensorsStore.hoverMarker(null);
      }
    });
    this.olmap.on('click', (e) => {
      let trigered = false;
      this.olmap.forEachFeatureAtPixel(e.pixel, (feature) => {
        console.log(feature);
        if (!trigered) {
          this.$store.dispatch('map/setSelectedItem', undefined);
          let feat = feature;

          if (feature && feature.get('features') && feature.get('features').length === 1) {
            feat = feature.get('features')[0];
          }
          if (feature && feature.get('features') && feature.get('features').length > 1) {
            this.mapView.setZoom(this.mapView.getZoom() + 0.33333333);
            this.mapView.setCenter(feature.getGeometry().getCoordinates());
          }
          if (feat.getId()) {
            const data = feat.get('record');
            console.log({ data });
            if (data && data.typeOfItem === 'cameras') {
              this.selectCamera(data);
            } else if (data && data.typeOfItem === 'lprs') {
              this.selectLpr(data);
            } else if (data && data.typeOfItem === 'devices') {
              this.selectDevice(data);
            } else if (data && data.typeOfItem === 'sensors') {
              this.selectSensor(data);
            } else if (data && data.typeOfItem === 'incidents') {
              this.selectIncident(data);
            } else if (data && data.typeOfItem === 'sosIncident') {
              this.selectSOSIncident(data);
            } else if (data && data.typeOfItem === 'geofence') {
              this.$store.dispatch('map/setSelectedItem', data);
              if (data.attributes?.devices) {
                const devices = data.attributes?.devices
                const _infoFields = devices.map((device) => ({
                  label: device.name,
                  value: device.driverName
                }));
                _infoFields.unshift({
                  label: 'DISPOSITIVOS',
                  type: 'title',
                });
                this.$store.dispatch('map/setSelectedItem', { ...data, _infoFields });
              }
            } else if (data && data.typeOfItem) {
              this.$store.dispatch('map/setSelectedItem', data);
            }
          }
        }
        trigered = true;
      });
    });
    this.olmap.getView().on('change:rotation', (event) => {
      const rosavientos = document.getElementById('rosavientos');
      if (rosavientos) {
        rosavientos.style.setProperty('transform', `rotate(${event.target.getRotation()}rad)`);
      }
    });
    this.load().then(() => {
      this.loading = false;
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const pathname = window.location.pathname.substring(
        0,
        window.location.pathname.lastIndexOf('/') + 1
      );

      const closed = false;
      const connect = () => {
        let error = false;
        // console.log('aqui ysso', this.ws?.readyState, WebSocket.OPEN, 'as')
        if (this?.ws?.readyState === WebSocket.OPEN) {
          this.ws.send('ping');
        }

        if (!this.ws) {
          this.ws = new WebSocket(protocol + '//' + window.location.host + pathname + 'api/socket');
        } else if (this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING) {
          this.ws = new WebSocket(protocol + '//' + window.location.host + pathname + 'api/socket');
        } else {
          // console.log('Socket is already open');
          return;
        }
        console.log('Socket connecting...');
        this.ws.onopen = () => {
          if (closed) {
            console.log('Socket reconnected!!');
          }
        };
        this.ws.onmessage = (e) => {
          const _data = JSON.parse(e.data);
          if (_data.cameras) {
            _data.cameras.forEach((camera) => {
              this.loadCamera(camera);
            });
          }
          if (_data.lprs) {
            _data.lprs.forEach((lpr) => {
              this.loadLpr(lpr);
            });
          }
          if (_data.devices) {
            _data.devices.forEach((device) => {
              if (device.category) {
                device.categoryData = this.device_categories[device.category];
              }
              this.loadDevice(device);
            });
          }
          if (_data.positions) {
            _data.positions.forEach((position) => {
              this.loadPosition(position);
            });
          }
          if (_data.incidents) {
            _data.incidents.forEach((incident) => {
              if (incident.category) {
                incident.categoryData = this.incident_categories[incident.category];
              }
              this.loadIncident(incident);
            });
          }
          if (_data.sensors) {
            _data.sensors.forEach((sensor) => {
              this.loadSensor(sensor);
            });
          }
          if (_data.murphy_hits) {
            _data.murphy_hits.forEach((hit) => {
              this.loadHit(hit);
            });
          }

          if (_data.sos_incidents) {
            _data.sos_incidents.forEach((item) => {
              this.loadSOSIncident(item);
            });
          }
          if (_data.events) {
            _data.events.forEach((event) => {
              this.loadDeviceEvent(event);
            });
          }
          if (_data.geofences) {
            _data.geofences.forEach((geofence) => {
              this.loadGeofence(geofence);
            });
          }
          if (_data.geofence_remove) {
            _data.geofence_remove.forEach((geofence) => {
              this.deleteGeofence(geofence.id);
            });
          }
        };
        this.ws.onerror = (err) => {
          console.error('Socket encountered error: ', { err });
          // window.VMA.showError({ title: 'Socket Error, Reconec tando...', body: err.message });
          error = true;
          if (this.ws) {
            this.ws.close();
          }
          // this.ws = null
        };
        this.ws.onclose = () => {
          // closed = true;
          console.log('error', error);
        };
      };
      connect();

      this.timerSocket = setInterval(() => {
        connect();
      }, 3000);
    });
  },
  unmouted() {
    console.log('unmouted');
    clearInterval(this.timerSocket);
    if (this.ws) {
      this.ws.close();
    }
    if (this.trip) {
      this.trip.close();
    }
    this.ws = null;
    this.trip = null;
  },
  beforeDestroy() {
    this.controls.rightPanel.component.$destroy();
    this.controls.mapActions.component.$destroy();
    this.controls.mapToolBar.component.$destroy();
    this.controls.leftPanel.component.$destroy();
    this.controls.mapType.component.$destroy();
  },
  destroyed() {
    clearInterval(this.timerSocket);
    if (this.ws) {
      this.ws.close();
    }
    if (this.trip) {
      this.trip.close();
    }
    this.trip = null;
    this.ws = null;
  },
  methods: {
    ...mapActions('alerts', [
      'addLprHitAlerts',
      'addSensorAlerts',
      'removeSensorAlerts',
      'addIncidentAlerts',
      'removeIncidentAlerts',
      'addDeviceAlerts',
      'cleanAlerts',
      'loadHits',
      'addHit'
    ]),
    ...mapActions('map', ['setShowGeofences']),
    execFunction(func) {
      func.apply(this);
    },
    load() {
      this.cleanAlerts();
      return new Promise((res) => {
        ApiService({
          url: '/groups',
          method: 'get',
        })
          .then((resp) => {
            this.groups = resp.toObject('id');
          })
          .catch((err) => {
            console.error('LoadGroups', { err });
            window.VMA.showError({ title: 'Ocurrió un error al cargar los grupos' });
          })
          .finally(() => {
            const camerasPromise = new Promise((resolve) => {
              ApiService({
                url: '/cameras',
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((item) => {
                    this.loadCamera(item);
                  });
                })
                .catch((err) => {
                  console.error('LoadCameras', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar las camaras' });
                })
                .finally(() => {
                  resolve();
                });
            });
            const lprPromise = new Promise((resolve) => {
              ApiService({
                url: '/lpr',
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((item) => {
                    this.loadLpr(item);
                  });
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('LoadLPRs', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar los lprs' });
                })
                .finally(() => {
                  resolve();
                });
            });
            const unidadesPromise = new Promise((resolve) => {
              const positionsPromise = ApiService({
                url: '/positions',
                method: 'get',
              });

              const deviceCategoriesPromise = ApiService({
                url: '/v2/device-categories',
                methos: 'get',
              });

              const devicesPromise = ApiService({
                url: '/devices',
                method: 'get',
              });

              Promise.all([deviceCategoriesPromise, devicesPromise, positionsPromise])
                .then((responses) => {
                  this.device_categories = responses[0].toObject('id');
                  responses[1].forEach((item) => {
                    if (item.category) {
                      item.categoryData = this.device_categories[item.category];
                    }
                    this.loadDevice(item);
                  });
                  responses[2].forEach((position) => {
                    this.loadPosition(position);
                  });
                })
                .catch((err) => {
                  console.error('LoadDevices', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar las unidades' });
                })
                .finally(resolve);
            });
            const incidentsPromise = new Promise((resolve) => {
              const incidentTypesPromise = ApiService({
                url: '/v2/incident-categories',
                methos: 'get',
              });

              const _incidentsPromise = ApiService({
                url: '/incidents',
                method: 'get',
              })

              Promise.all([incidentTypesPromise, _incidentsPromise])
                .then((responses) => {
                  this.incident_categories = responses[0].toObject('uniqueId');
                  responses[1].forEach((item) => {
                    if (item.category) {
                      item.categoryData = this.incident_categories[item.category];
                    }
                    this.loadIncident(item);
                  });
                })
                .catch((err) => {
                  console.error('LoaIncidents', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar los incidentes' });
                })
                .finally(resolve);
            });
            const sensorsPromise = new Promise((resolve) => {
              ApiService({
                url: '/sensor',
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((item) => {
                    this.loadSensor(item);
                  });
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('LoadSensors', { err });
                  window.VMA.showError({
                    title: 'Ocurrió un error al cargar los botones de pánico',
                  });
                })
                .finally(() => {
                  resolve();
                });
            });
            const geofencesPromise = new Promise((resolve) => {
              ApiService({
                url: '/geofences',
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((item) => {
                    this.loadGeofence(item);
                  });
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('LoadGeofences', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar las geocercas' });
                })
                .finally(() => {
                  resolve();
                });
            });
            const hitsPromise = new Promise((resolve) => {
              ApiService({
                url: '/murphy/lasthits',
                method: 'get',
              })
                .then((resp) => {
                  this.loadHits(resp);
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('LoadLastHits', { err });
                  window.VMA.showError({ title: 'Ocurrió un error al cargar los hits' });
                })
                .finally(() => {
                  resolve();
                });
            });
            const sosIncidentsPromise = new Promise((resolve) => {
              ApiService({
                url: '/sosincidents?status=active',
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((item) => {
                    this.loadSOSIncident(item);
                  });
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('LoadSOSIncidents', { err });
                  window.VMA.showError({
                    title: 'Ocurrió un error al cargar los incidentes de sos',
                  });
                })
                .finally(() => {
                  resolve();
                });
            });
            const deviceAlertsPromise = new Promise((resolve) => {
              ApiService({
                url: '/events/recents',
                method: 'get',
              })
                .then((resp) => {
                  resp.sort((x, y) => x.id - y.id).forEach((item) => {
                    this.loadDeviceEvent(item);
                  });
                  resolve(resp);
                })
                .catch((err) => {
                  console.error('eventos recientes', { err });
                })
                .finally(() => {
                  resolve();
                });
            });
            Promise.all([
              camerasPromise,
              lprPromise,
              unidadesPromise,
              incidentsPromise,
              sensorsPromise,
              geofencesPromise,
              hitsPromise,
              sosIncidentsPromise,
              deviceAlertsPromise,
            ]).finally(res);
          });
      });
    },
    loadCamera(item) {
      item.groupName = this.groups[item.groupId]?.name || '';
      this.cameraStore.updateMarker(item);
    },
    loadDeviceEvent(event) {
      const device = this.devicesStore.markers.get(event.deviceId).get('record');
      const exist = this.getDeviceAlerts.find((a) => a.id === event.id);
      if (device) {
        const item = {
          ...device,
          _addedAt: new Date(event.serverTime)
        };
        if (event.type === 'alarm') {
          event.attributes = event.attributes || {}
          if (!event.attributes.descripcion) {
            event.attributes.descripcion = alarmTypes[event.attributes.alarm]?.name || ''
          }
        }
        item.event = {
          ...event,
          descripcion: event.attributes?.descripcion || event.descripcion || event.type,
          createdAt: new Date(event.serverTime),
        };
        if (!this.loading && !exist) {
          window.VMA.showMessage({
            title: 'Evento de dispositivo - ' + item.name,
            body: item.event.descripcion,
            icon: '$device-white',
            color: event.attributes?.type === 5 ? 'error' : 'var(--kalan-orange)',
            onClick: () => {
              const it = this.devicesStore.markers.get(event.deviceId).get('record');
              this.selectDevice(it, true, true);
            },
            onShown: () => {
              if (event.attributes?.type === 5) {
                this.audio.play();
                this.audio.loop = true;
              } else {
                this.deviceEventAudio.play();
              }
            },
            onClose: () => {
              if (event.attributes?.type === 5) {
                this.audio.pause();
              }
            },
          });
        }
        this.addDeviceAlerts(item);
      }
    },
    loadLpr(item) {
      item.groupName = this.groups[item.groupId]?.name || '';
      this.lprsStore.updateMarker(item);
    },
    loadDevice(item) {
      item.groupName = this.groups[item.groupId]?.name || '';
      this.devicesStore.updateMarker(item);
    },
    loadIncident(item) {
      item.groupName = this.groups[item.groupId]?.name || '';
      if (item.status === 'ACTIVO') {
        if (!this.loading && this.getIncidentAlerts.findIndex((incident) => incident.id === item.id) === -1) {
          window.VMA.showMessage({
            title: 'Nuevo incidente - ' + item.uniqueId,
            body: item.tipoIncidente,
            icon: Util.incidentsAlertMap[item.category].icon,
            color: Util.incidentsAlertMap[item.category].color,
            onClick: () => {
              const record = this.incidentsStore.getRecord(item.id);
              if (record.typeOfItem === 'sosIncident') {
                this.selectSOSIncident(record, true);
              } else {
                this.selectIncident(record, true);
              }
            },
          });
        }
        this.addIncidentAlerts(item);
        this.incidentsHeatMapStore.addFeature(item);
      } else if (item.status === 'HISTORY') {
        this.removeIncidentAlerts(item.uniqueId);
        this.removeFeature.removeFeatureById(item.id);
      }
      this.incidentsStore.updateMarker(item);
    },
    loadSensor(item) {
      item.groupName = this.groups[item.groupId]?.name || '';
      this.sensorsStore.updateMarker(item);
      if (!this.loading && item.status === 'alert') {
        window.VMA.showMessage({
          title: 'Botón de Panico Activado',
          body: item.name,
          icon: '$sensor-white',
          onClick: () => {
            this.selectSensor(item, true);
          },
          onShown: () => {
            this.audio.play();
            this.audio.loop = true;
          },
          onClose: () => {
            this.audio.pause();
          },
          color: 'error',
        });
      }
      if (item.status === 'alert') {
        this.addSensorAlerts(item);
      } else if (item.status === 'up' || item.status === 'reported') {
        this.removeSensorAlerts(item.uniqueId);
      }
    },
    loadSOSIncident(item) {
      const uniqueId = item.origenCad + item.folioCad.substr(item.folioCad.length - 7);
      const incident = this.incidentsStore.getRecords().filter((i) => i.uniqueId === uniqueId)[0];
      if (incident) {
        const _incident = { ...incident };
        _incident.typeOfItem = 'sosIncident';
        _incident.latitude = item.lastPositionLatitude;
        _incident.longitude = item.lastPositionLongitude;
        _incident.sosData = item;
        this.incidentsStore.updateMarker(_incident);
      } else {
        this.incidentsStore.sosIncidents.set(uniqueId, item);
      }
    },
    loadPosition(item) {
      this.devicesStore.updatePosition(item);
    },
    deleteGeofence(id) {
      const item = this.geofences[id];
      delete this.geofences[id];
      if (item) {
        const feature = this.geofencesLayer.getSource().getFeatureById(item.id);
        this.geofencesLayer.getSource().removeFeature(feature);
        const routeFeature = this.geofencesRoutesLayer.getSource().getFeatureById(item.id);
        if (routeFeature) {
          this.geofencesRoutesLayer.getSource().removeFeature(routeFeature);
        }
        if (item.attributes.points) {
          item.attributes.points.forEach((r) => {
            const featurePoint = this.geofencesPointsLayer.getSource().getFeatureById(r.folio);
            this.geofencesPointsLayer.getSource().removeFeature(featurePoint);
          });
        }
      }
    },
    loadGeofence(item) {
      if (this.geofences[item.id]) {
        this.deleteGeofence(item.id);
        // const feature = this.geofencesLayer.getSource().getFeatureById(item.id);
        // this.geofencesLayer.getSource().removeFeature(feature)
      }
      this.loadGeofencePoints(item, this.geofencesPointsLayer);
      if (item.attributes.showRoute && item.attributes.route) {
        const routeFeature = new ol.Feature({
          geometry: new ol.geom.LineString(
            item.attributes.route.map((r) => ol.proj.fromLonLat([r.longitude, r.latitude]))
          ),
        });
        routeFeature.setId(item.id);
        this.geofencesRoutesLayer.getSource().addFeature(routeFeature);
      }
      this.items.push({
        id: item.id,
        name: item.name,
        typeOfItem: 'geofence',
      });
      const geojsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:3857',
          },
        },
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                item.area
                  .replace('POLYGON((', '')
                  .replace('))', '')
                  .split(', ')
                  .map((a) => {
                    const w = a.split(' ');
                    return window.ol.proj.fromLonLat([w[1], w[0]]);
                  }),
              ],
            },
          },
        ],
      };
      const geofence = new ol.format.GeoJSON().readFeatures(geojsonObject);
      geofence[0].setId(item.id);
      geofence[0].set('record', { ...item, typeOfItem: 'geofence' });
      this.geofencesLayer.getSource().addFeatures(geofence);
      this.geofences[item.id] = { ...item, _geofence: geofence };
    },
    loadGeofencePoints(item, layer) {
      if (item.attributes.points) {
        item.attributes.points.forEach((r) => {
          const pointFeature = new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.fromLonLat([r.longitude, r.latitude])),
          });
          pointFeature.setId(r.folio);
          pointFeature.set('record', {
            ...r,
            geofence: item.name,
            color: item.attributes.color,
            conductor: item.attributes.conductor,
            typeOfItem: 'geofencePoint',
          });
          layer.getSource().addFeature(pointFeature);
        });
      }
    },
    loadDeviceGeofence(item) {
      const geojsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:3857',
          },
        },
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                item.area
                  .replace('POLYGON((', '')
                  .replace('))', '')
                  .split(', ')
                  .map((a) => {
                    const w = a.split(' ');
                    return window.ol.proj.fromLonLat([w[1], w[0]]);
                  }),
              ],
            },
          },
        ],
      };
      const geofence = new ol.format.GeoJSON().readFeatures(geojsonObject);
      geofence[0].setId(item.id);
      geofence[0].set('record', { ...item, typeOfItem: 'geofence' });
      this.deviceGeofencesLayer.getSource().addFeatures(geofence);
      this.loadGeofencePoints(item, this.deviceGeofencesLayer);
    },
    loadHit(item) {
      const data = {};
      if (item.hitType === 'Mandamiento') {
        const mandamiento = item.attributes.Mandamientos;
        item.typeOfItem = 'hitMandamiento';
        data.title = 'Mandamiento';
        data.body = `${mandamiento.dnombre} ${mandamiento.dpaterno} ${mandamiento.dmaterno}`;
      } else if (item.hitType === 'VehiculoRobado') {
        const vehiculo = item.attributes.Vehiculo;
        item.typeOfItem = 'hitRoboVehiculo';
        data.title = 'Robo de Vehiculo';
        data.body = `${vehiculo.PLACA} ${vehiculo.MARCA} ${vehiculo.LINEA} ${vehiculo.COLOR}`;
      } else {
        const vehiculo = item.attributes;
        item.typeOfItem = 'hitLpr';
        data.title = 'Deteccion LPR';
        data.body = `${vehiculo.placa} ${vehiculo.marca} ${vehiculo.linea} ${vehiculo.modelo}`;
      }
      window.VMA.showMessage({
        title: data.title,
        body: data.body,
        color: 'error',
        key: 'hit' + item.id,
        onClick: () => {
          this.selectHit(item, false);
        },
        onShown: () => {
          this.audio.play();
          this.audio.loop = true;
        },
        onClose: () => {
          this.audio.pause();
        },
      });
      this.addHit(item);
    },
    selectHit(item) {
      this.clearSelection();
      this.selectedItem = item;
      this.selectedType = 'hit';
      this.selectedItem.selected = true;
      this.$store.dispatch('map/setSelectedItem', {
        ...item,
      });
    },
    updateMarkersStyle() {
      this.cameraStore.updateMarkersStyle();
      this.lprsStore.updateMarkersStyle();
      this.devicesStore.updateMarkersStyle();
      this.sensorsStore.updateMarkersStyle();
      this.incidentsStore.updateMarkersStyle();
    },
    setPlace(place) {
      this.setCenter(place.geometry.location.lat(), place.geometry.location.lng());
    },
    clearSelection() {
      if (this.selectedItem) {
        this.selectedItem.selected = false;
        switch (this.selectedType) {
          case 'incident':
            this.incidentsStore.updateMarker(this.selectedItem);
            break;
          case 'camera':
            this.cameraStore.updateMarker(this.selectedItem);
            break;
          case 'lpr':
            this.lprsStore.updateMarker(this.selectedItem);
            break;
          case 'device':
            this.devicesStore.updateMarker(this.selectedItem);
            break;
          case 'sensor':
            this.sensorsStore.updateMarker(this.selectedItem);
            break;
          default:
            break;
        }
      }
    },
    selectCamera(item, center = false) {
      this.clearSelection();

      this.selectedItem = item;
      this.selectedType = 'camera';
      this.selectedItem.selected = true;
      this.cameraStore.updateMarker(item);

      this.$store.dispatch('map/setSelectedItem', {
        ...item,
        actions: [
          {
            icon: 'mdi-camera',
            help: 'VER CAMARAS',
            condition: () => item.status === 'up',
            action: () => {
              console.log('item...', item);
              this.$refs.camerasWidget.openCamera(item, 'pmi');
              // this.openCamera(item)
            },
          },
          {
            icon: 'mdi-file-document-edit-outline',
            help: 'REPORTE',
            action: () => {
              const form = new Form({
                schema: {
                  motivo: {
                    label: 'Motivo',
                    type: String,
                    options: motivosCad.map((m) => ({ id: m, name: m })),
                  },
                  descripcion: {
                    label: 'Descripción',
                    type: String,
                    textarea: true,
                  },
                },
              });
              const dialog = new Dialog({
                title: 'Reporte',
                titleColor: 'secondary',
                actions: [
                  {
                    color: 'success',
                    help: 'Guardar',
                    icon: 'mdi-content-save',
                    action: () => {
                      if (form.hasErrors()) {
                        return;
                      }
                      window.VMA.loading(true);
                      ApiService({
                        url: 'cameras/' + item.uniqueId + '/reportalert',
                        method: 'get',
                        params: form.getItem(),
                      })
                        .then(() => {
                          dialog.close();
                        })
                        .catch((err) => {
                          console.error({ err });
                          window.VMA.showError({ title: 'Ocurrió un error al reportar la alerta' });
                        })
                        .finally(() => {
                          window.VMA.loading(false);
                        });
                    },
                  },
                ],
              });
              dialog.open();
              dialog.append(form);
            },
          },
        ],
      });
      if (center) {
        this.setCenter(item.latitude, item.longitude);
      }
    },
    selectLpr(item, center = false) {
      this.clearSelection();

      this.selectedItem = item;
      this.selectedType = 'lpr';
      this.selectedItem.selected = true;
      this.lprsStore.updateMarker(item);

      this.$store.dispatch('map/setSelectedItem', item);
      if (center) {
        this.setCenter(item.latitude, item.longitude);
      }
    },
    async selectDevice(it, center = false, showCameras = false) {
      this.clearSelection();
      if (showCameras && it?.attributes?.isDvr) {
        this.openDeviceCameras(it);
      }
      if (it.attributes?.conductor) {
        try {
          const persona = await ApiService({
            url: `/v2/drivers/${it.attributes.conductor}`,
            method: 'get',
          });
          it.user = persona;
        } catch (error) {
          console.log(error.code);
        }
      }
      this.selectedItem = it;
      this.selectedType = 'device';
      this.selectedItem.selected = true;
      this.devicesStore.updateMarker(it);

      this.$store.dispatch('map/setSelectedItem', {
        ...it,
        actions: [
          {
            icon: 'mdi-lock',
            help: 'CANDADOS',
            condition: () => it?.attributes?.havePadLock,
            action: () => {
              new Vue({
                vuetify,
                ...DeviceLocks,
                propsData: { device: { ...it } },
              }).$mount();
            },
          },
          {
            icon: 'mdi-motion-play-outline',
            help: 'GRABACIONES',
            condition: () => this?.getUser?.attributes?.permission_recordings,
            action: () => {
              new Vue({
                vuetify,
                ...DeviceCamerasDownloads,
                propsData: { device: { ...it } },
              }).$mount()
            },
          },
          {
            icon: '$geofence-white',
            help: 'VER GEOCERCAS',
            action: () => {
              this.deviceGeofencesLayer.getSource().clear();
              this.setShowGeofences(false);
              ApiService({
                url: `/geofences?_dc=1695234394001&deviceId=${it.id}`,
                method: 'get',
              })
                .then((resp) => {
                  resp.forEach((geofence) => {
                    console.log(geofence);
                    this.loadDeviceGeofence(geofence);
                  });
                })
                .catch((err) => {
                  console.error('LoadGeofences', { err });
                  window.VMA.showError({
                    title: 'Ocurrió un error al cargar las geocercas de dispositivo ' + it.name,
                  });
                });
            },
          },
          {
            icon: 'mdi-camera',
            help: 'VER CAMARAS',
            condition: () => it?.attributes?.isDvr,
            action: () => {
              this.openDeviceCameras(it)
            },
          },
          {
            icon: this.devicesStore.followedFeatureId === it.id ? 'mdi-eye-off' : 'mdi-eye',
            help: this.devicesStore.followedFeatureId === it.id ? 'Dejar de seguir' : 'Seguir',
            action: (item) => {
              if (this.devicesStore.followedFeatureId === it.id) {
                this.devicesStore.unfollowFeature();
                item.icon = 'mdi-eye';
                item.help = 'Seguir';
                return;
              }
              this.devicesStore.followFeatureById(it.id);
              item.icon = 'mdi-eye-off';
              item.help = 'Dejar de seguir';
            },
          },
          {
            icon: 'mdi-map-marker-multiple',
            help: 'COPIAR COORDENADAS DE UBICACIÓN',
            action: () => {
              window.navigator.clipboard.writeText(
                `${it.position?.latitude},${it.position?.longitude}`
              );
            },
          },
        ],
      });

      if (it.position && center) {
        this.setCenter(it.position.latitude, it.position.longitude);
      }
    },
    openDeviceCameras(it) {
      const tmpitem = {
        ...it,
        attributes: it.attributes.cameras.reduce((x, y) => {
          x[y.name] = btoa(JSON.stringify(y));
          return x;
        }, {}),
      };
      this.$refs.camerasWidget.openCamera(tmpitem, 'device');
    },
    selectIncident(item, center = false) {
      this.clearSelection();

      this.selectedItem = item;
      this.selectedType = 'incident';
      this.selectedItem.selected = true;
      this.incidentsStore.updateMarker(item);

      this.$store.dispatch('map/setSelectedItem', {
        ...item,
        actions: [
          {
            icon: 'mdi-camera',
            help: 'VER CAMARAS AL REDEDOR',
            action: () => {
              this.$refs.camerasWidget.openCamerasAround(item, this.cameraStore);
              // this.openAllCamerasAround(item, 0.5)
            },
          },
          {
            icon: 'mdi-text-box-plus',
            help: 'AGREGAR RAZONAMIENTO',
            action: () => {
              this.addIncidentReasoning(item);
            },
          },
          {
            icon: 'mdi-map-marker-multiple ',
            help: 'COPIAR COORDENADAS DE UBICACIÓN',
            action: () => {
              window.navigator.clipboard.writeText(`${item.latitude},${item.longitude}`);
            },
          },
        ],
      });
      if (center) {
        this.setCenter(item.latitude, item.longitude);
      }
    },
    addIncidentReasoning(item) {
      const form = new Form({
        schema: razonamientoModel,
        item: {
          incidentId: item.uniqueId,
          user: this.getUser.name || 'Desconocido',
          corporation: this.getUser.workUnit || 'Desconocido',
        },
      });
      const dialog = new Dialog({
        title: 'Crear incidente',
        titleColor: 'secondary',
        actions: [
          {
            color: 'success',
            help: 'Guardar',
            icon: 'mdi-content-save',
            action: () => {
              if (form.hasErrors()) {
                return;
              }
              window.VMA.loading(true);
              axios({
                url: '/api/cadinc/createRazonamiento',
                method: 'post',
                data: form.getItem(),
              })
                .then((res) => {
                  console.log(res.data);
                  this.loadIncident(res.data);
                  this.selectIncident(res.data);
                  dialog.close();
                })
                .catch((err) => {
                  console.error({ err });
                  window.VMA.showError({ title: 'Ocurrió un error al crear el incidente' });
                })
                .finally(() => {
                  window.VMA.loading(false);
                });
            },
          },
        ],
      });
      dialog.open();
      dialog.append(form);
    },
    selectSOSIncident(item, center = false) {
      this.clearSelection();
      this.selectedItem = item;
      this.selectedType = 'sosIncident';
      this.selectedItem.selected = true;
      // this.incidentsStore.updateMarker(item)

      this.$store.dispatch('map/setSelectedItem', {
        ...item,
        actions: [
          {
            icon: 'mdi-camera',
            help: 'VER LLAMADA',
            action: () => {
              this.openSOSCamera(item.sosData);
            },
          },
        ],
      });
      if (center) {
        this.setCenter(item.sosData.lastPositionLatitude, item.sosData.lastPositionLongitude);
      }
    },
    selectSensor(item, center = false) {
      this.clearSelection();

      this.selectedItem = item;
      this.selectedType = 'sensor';
      this.selectedItem.selected = true;
      this.sensorsStore.updateMarker(item);
      this.$store.dispatch('map/setSelectedItem', {
        ...item,
        actions: [
          {
            icon: 'mdi-camera',
            help: 'VER CAMARAS',
            condition: () => ['reported', 'alert'].includes(item.status),
            action: () => {
              this.$refs.camerasWidget.openCamera(item, 'pmi');
              // this.openCamera(item)
            },
          },
          {
            icon: 'mdi-check',
            help: 'CERRAR ALERTA',
            condition: () => item.status === 'reported',
            action: () => {
              this.$store.dispatch('alerts/closeSensorAlerts', item.uniqueId);
            },
          },
          {
            icon: 'mdi-file-document-edit-outline',
            help: 'REPORTE',
            condition: () => item.status === 'alert',
            action: () => {
              const form = new Form({
                schema: {
                  tipo: {
                    label: 'Tipos',
                    type: String,
                    options: [
                      {
                        id: 'nueva',
                        name: 'Nueva',
                      },
                      {
                        id: 'existente',
                        name: 'Existente',
                      },
                    ],
                    onChange: (val, fields) => {
                      fields.motivo.setVisibility(val === 'nueva');
                      fields.descripcion.setVisibility(val === 'nueva');
                      fields.foliocad.setVisibility(val === 'existente');
                      fields.origen.setVisibility(val === 'existente');
                    },
                  },
                  motivo: {
                    label: 'Motivo',
                    type: String,
                    options: motivosCad.map((m) => ({ id: m, name: m })),
                    visibility: false,
                  },
                  descripcion: {
                    label: 'Descripción',
                    type: String,
                    textarea: true,
                    visibility: false,
                  },
                  foliocad: {
                    label: 'Folio',
                    type: String,
                    visibility: false,
                  },
                  origen: {
                    label: 'Origen del Folio',
                    type: String,
                    options: ['C4', 'CERI', 'C4 MAZATLAN', 'C4 MOCHIS'].map((m) => ({
                      id: m,
                      name: m,
                    })),
                    visibility: false,
                  },
                },
              });
              const dialog = new Dialog({
                title: 'Reporte',
                titleColor: 'secondary',
                actions: [
                  {
                    color: 'success',
                    help: 'Guardar',
                    icon: 'mdi-content-save',
                    action: () => {
                      if (form.hasErrors()) {
                        return;
                      }
                      let data = {};
                      const it = form.getItem();
                      if (it.tipo === 'nueva') {
                        data = _.pick(it, 'motivo', 'descripcion');
                      } else {
                        data = _.pick(it, 'foliocad', 'origen');
                      }
                      window.VMA.loading(true);
                      ApiService({
                        url: 'sensor/' + item.uniqueId + '/reportalert',
                        method: 'post',
                        data,
                      })
                        .then(() => {
                          dialog.close();
                        })
                        .catch((err) => {
                          console.error({ err });
                          window.VMA.showError({ title: 'Ocurrió un error al reportar la alerta' });
                        })
                        .finally(() => {
                          window.VMA.loading(false);
                        });
                    },
                  },
                ],
              });
              dialog.open();
              dialog.append(form);
            },
          },
          {
            icon: 'mdi-delete',
            help: 'DESCARTAR ALERTA',
            condition: () => item.status === 'alert',
            action: () => {
              this.$store.dispatch('alerts/discardSensorAlerts', item.uniqueId);
            },
          },
          {
            icon: 'mdi-alert-plus',
            help: 'CREAR ALERTA',
            condition: () => item.status === 'up' && this.getUser.attributes.alarm_sensor,
            action: () => {
              this.$store.dispatch('alerts/createSensorAlerts', item.uniqueId);
            },
          },
        ],
      });
      if (center) {
        this.setCenter(item.latitude, item.longitude);
      }
    },
    setCenter(lat, lng, zoom = 17) {
      this.mapView.setCenter(window.ol.proj.fromLonLat([lng, lat]));
      this.setZoom(zoom);
    },
    setZoom(zoom) {
      this.mapView.setZoom(zoom);
    },
    getCenter() {
      return window.ol.proj.toLonLat(this.mapView.getCenter());
    },
    openCamera(camera) {
      const cameras = Object.keys(camera.attributes).filter(
        // eslint-disable-next-line no-restricted-globals
        (key) => key.slice(0, 3) === 'dev' && !isNaN(parseInt(key.slice(3)))
      );
      const type = 'pmi';
      const cameraShownId = type + '_' + camera.uniqueId;
      let top = 10;
      let left = 10;
      const width = 480;
      const height = 320;

      if (this.camerasShown[cameraShownId] && !_.isEmpty(this.camerasShown[cameraShownId])) {
        return;
      }
      this.camerasShown[cameraShownId] = {};
      cameras
        .map((key) => camera.attributes[key])
        .forEach((cam, key) => {
          if (left + width > window.innerWidth) {
            left = 10;
            top += height + 40;
          }
          const cameraWindow = new CameraWindow({
            name: key,
            item: camera,
            type,
            num: key + 1,
            camera: cam,
            width,
            height,
            top,
            left,
            actions: [
              {
                icon: 'mdi-close-box-multiple',
                help: '',
                action: () => {
                  Object.keys(this.camerasShown[cameraShownId]).forEach((_key) => {
                    if (this.camerasShown[cameraShownId][_key]) {
                      this.camerasShown[cameraShownId][_key].component.close();
                    }
                  });
                },
              },
            ],
            onClose: () => {
              delete this.camerasShown[cameraShownId][key];
            },
          });
          left += width;
          this.$refs.map.append(cameraWindow.get());
          this.camerasShown[cameraShownId][key] = cameraWindow;
        });
    },
    openCameras(cameras) {
      const getMaxSizeOfSquaresInRect = (n, w, h) => {
        let sw;
        let sh;
        const pw = Math.ceil(Math.sqrt((n * w) / h));
        if (Math.floor((pw * h) / w) * pw < n) sw = h / Math.ceil((pw * h) / w);
        else sw = w / pw;
        const ph = Math.ceil(Math.sqrt((n * h) / w));
        if (Math.floor((ph * w) / h) * ph < n) sh = w / Math.ceil((w * ph) / h);
        else sh = h / ph;
        return Math.max(sw, sh);
      };
      const allCameras = cameras.reduce((acc, camera) => {
        return acc.concat(
          Object.keys(camera.attributes)
            .filter((key) => key.slice(0, 3) === 'dev' && _.isNumber(parseInt(key.slice(3))))
            .map((key, index) => ({
              item: _.omit(camera, '_marker'),
              num: index + 1,
              camera: camera.attributes[key],
            }))
        );
      }, []);
      const size = getMaxSizeOfSquaresInRect(
        allCameras.length,
        window.innerWidth,
        window.innerHeight
      );
      const perLine = parseInt((window.innerWidth / size).toFixed());
      let counter = 0;
      let line = 0;
      allCameras.forEach((camera) => {
        const cameraShownId = 'pmi_' + camera.item.uniqueId;
        if (!this.camerasShown[cameraShownId]) {
          this.camerasShown[cameraShownId] = {};
        }
        if (!this.camerasShown[cameraShownId][camera.num]) {
          const window = new CameraWindow({
            ...camera,
            type: 'pmi',
            top: size * line,
            left: size * counter,
            width: size,
            height: size - 40,
            actions: [
              {
                icon: 'mdi-close-box-multiple',
                help: '',
                action: () => {
                  Object.keys(this.camerasShown).forEach((key) => {
                    Object.keys(this.camerasShown[key]).forEach((_key) => {
                      if (this.camerasShown[key][_key]) {
                        this.camerasShown[key][_key].component.close();
                      }
                    });
                  });
                },
              },
            ],
            onClose: () => {
              delete this.camerasShown[cameraShownId][camera.num];
            },
          });
          this.$refs.map.append(window.get());
          this.camerasShown[cameraShownId][camera.num] = window;
          counter++;
          if (counter === perLine) {
            counter = 0;
            line++;
          }
        }
      });
    },
    openSOSCamera(item) {
      const top = 10;
      const left = 10;
      const width = 480;
      const height = 320;
      const cameraWindow = new CameraWindow({
        name: item.uniqueId,
        item,
        type: 'sos',
        camera: item.uniqueId,
        width,
        height,
        top,
        left,
      });
      this.$refs.map.append(cameraWindow.get());
    },
    findItems(val) {
      return [
        ...this.cameraStore.visibleItems,
        ...this.lprsStore.visibleItems,
        ...this.incidentsStore.visibleItems,
        ...this.sensorsStore.visibleItems,
        ...this.devicesStore.visibleItems,
      ].filter((item) => {
        const text = item.name;
        if (!text) {
          return false;
        }
        return text.toSlug().indexOf(val.toSlug()) > -1;
      });
    },
    openAllCamerasAround(point, distance) {
      const camerasAround = this.cameraStore
        .getRecords()
        .filter((camera) => camera.status === 'up' && this.getDistance(point, camera) <= distance);

      if (camerasAround.length === 0) {
        window.VMA.showError({
          title: 'No se encontraron camaras en los alrededores',
          color: 'warning',
        });
      } else {
        this.openCameras(camerasAround);
      }
    },
    getDistance(mk1, mk2) {
      const R = 6371.071; // Radius of the Earth in kilometers
      const rlat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
      const rlat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

      const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
      return d;
    },
    updateFilters() {
      this.updateCamerasFilter();
      this.updateLprsFilter();
      this.updateDevicesFilter();
      this.updateSensorsFilter();
      this.updateIncidentsFilter();
      this.updateGeofencesFilter();
    },
    updateCamerasFilter() {
      this.cameraStore.updateFilter(this.getCamerasFilters);
    },
    updateLprsFilter() {
      this.lprsStore.updateFilter(this.getLprsFilters);
    },
    updateDevicesFilter() {
      this.devicesStore.updateFilter(this.getDevicesFilters);
    },
    updateSensorsFilter() {
      this.sensorsStore.updateFilter(this.getSensorsFilters);
    },
    updateIncidentsFilter() {
      this.incidentsStore.updateFilter({
        ...this.getIncidentsFilters,
        status: this.show_incidents ? ['ACTIVO'] : [],
      });
    },
    updateGeofencesFilter() {
      if (this.show_geofences) {
        this.deviceGeofencesLayer.getSource().clear();
      }
      this.geofencesLayer.setVisible(this.show_geofences);
      this.geofencesPointsLayer.setVisible(this.show_geofences);
      this.geofencesRoutesLayer.setVisible(this.show_geofences);
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.loader = this.$loading.show({
          loader: 'dots',
        });
      } else {
        this.loader.hide();
      }
    },
    getCamerasFilters() {
      this.updateCamerasFilter();
    },
    getLprsFilters() {
      this.updateLprsFilter();
    },
    getDevicesFilters() {
      this.updateDevicesFilter();
    },
    getSensorsFilters() {
      this.updateSensorsFilter();
    },
    getIncidentsFilters() {
      this.updateIncidentsFilter();
    },
    show_cameras() {
      this.updateCamerasFilter();
    },
    show_lprs() {
      this.updateLprsFilter();
    },
    show_devices() {
      this.updateDevicesFilter();
    },
    show_sensors() {
      this.updateSensorsFilter();
    },
    show_incidents() {
      this.updateIncidentsFilter();
    },
    show_incidents_heat_map(val) {
      this.incidentsHeatMapLayer.setVisible(val);
    },
    show_geofences() {
      this.updateGeofencesFilter();
    },
    show_live_route(val) {
      this.liveRouteLayer.setVisible(val);
    },
  },
};
</script>

<style lang="scss">
.marker-label-camera {
  color: #ffffff !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: 30px;
}

.marker-label-lpr {
  color: #ffffff !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: 45px;
}

.marker-label-device {
  color: #ffffff !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: 30px;
}

.int {
  width: 100%;
  height: 100%;
}

.custom-map-control-button {
  appearance: button;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 10px;
  padding: 0 0.5em;
  height: 40px;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
}

.pac-target-input {
  height: 50px !important;
  width: 100%;
  font-size: 16px;
  padding: 10px;
}

.map {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}
</style>
