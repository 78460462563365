<template>
  <kalan-dialog
    :title="$vuetify.lang.t(`$vuetify.${model}.plural_name`)"
    height="650px"
    :actions="actions"
  >
    <v-card class="mx-auto elevation-0" height="100%">
      <kalan-list
        :loading="loading"
        :getTitle="getTitle"
        :getSubTitle="getSubTitle"
        :getExtra="getExtra"
        :items="items"
        :search="search"
        :actions="listActions"
      >
      </kalan-list>
    </v-card>
  </kalan-dialog>
</template>

<script>
import ApiService from '@/util/api.service';
import DeviceCamerasDownloads from '@/models/device-cameras-downloads.model';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import axios from 'axios';
import moment from 'moment';
import Dialog from '@/components/dialog';
import Form from '@/components/form';

async function downloadAndZipFiles(urls, zipName) {
  const zip = new JsZip();
  console.log({ urls });
  const promises = urls.map((url) => axios.get(url, { responseType: 'arraybuffer' }));
  const responses = await Promise.all(promises);
  responses.forEach((response, index) => {
    const fileName = urls[index].split('&name=').pop();
    zip.file(fileName, response.data);
  });
  const zipContent = await zip.generateAsync({ type: 'blob' });
  FileSaver.saveAs(zipContent, `${zipName}.zip`);
}

const downloadFileWithAnchor = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = true;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const taskStatusMessages = {
  '-6': 'La tarea está en pausa.',
  '-5': 'Conexiones limitadas.',
  '-4': 'En resolución.',
  '-3': 'No completada.',
  '-2': 'Espacio en disco insuficiente.',
  '-1': 'Esperando.',
  0: 'Resolución para completar.',
  1: 'Descargando.',
  2: 'No hay archivos de video.',
  3: 'Tarea completada.',
  4: 'Tarea fallida.',
  5: 'Eliminación.',
  6: 'Descarga fallida.',
  8: 'Tarea expirada.',
};

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'kalan-dialog': () => import('@/components/dialog/Dialog.vue'),
  },
  data() {
    return {
      model: 'device-cameras-downloads',
      items: [],
      loading: true,
      search: '',
      actions: [
        {
          help: 'Recargar',
          icon: 'mdi-refresh',
          color: 'orange',
          action: () => {
            this.load();
          },
        },
        {
          help: 'Agregar descarga',
          icon: 'mdi-plus',
          color: 'secondary',
          action: () => {
            const now = moment().format('YYYY-MM-DD HH:mm:ss');
            const before = moment().subtract(30, 'seconds').format('YYYY-MM-DD HH:mm:ss');
            const form = new Form({
              schema: DeviceCamerasDownloads,
              item: {
                from: before.slice(0, 10),
                fromHour: before.slice(11, 19),
                to: now.slice(0, 10),
                toHour: now.slice(11, 19),
              },
            });
            const dialog = new Dialog({
              title: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
              actions: [
                {
                  help: 'Guardar',
                  icon: 'mdi-content-save',
                  color: 'secondary',
                  action: async () => {
                    if (form.hasErrors()) {
                      return;
                    }
                    let _item = form.getItem();
                    _item = {
                      deviceId: this.device.id,
                      fromDate: `${moment(_item.from).format('YYYY-MM-DD')} ${_item.fromHour}`,
                      toDate: `${moment(_item.to).format('YYYY-MM-DD')} ${_item.toHour}`,
                    };
                    if (
                      !this.validateTime(_item.fromDate)
                      || !this.validateTime(_item.toDate)
                      || moment(_item.fromDate).isAfter(moment(_item.toDate))
                    ) {
                      window.VMA.showError({
                        title: 'La hora de inicio y fin no son válidas',
                      });
                      return;
                    }
                    if (moment(_item.toDate).diff(moment(_item.fromDate), 'minutes') > 30) {
                      window.VMA.showError({
                        title: 'El rango de fechas no puede ser mayor a 30 minutos',
                      });
                      return;
                    }
                    window.VMA.loading(true);
                    ApiService({
                      url: `/v2/device-cameras-downloads`,
                      method: 'post',
                      data: _item,
                    })
                      .then(() => {
                        dialog.close();
                        this.load();
                      })
                      .catch((err) => {
                        console.error({ err });
                        window.VMA.showError({ title: 'Error al guardar' });
                      })
                      .finally(() => {
                        window.VMA.loading(false);
                      });
                  },
                },
              ],
            });
            dialog.open();
            dialog.append(form);
          },
        },
      ],
      listActions: [
        {
          help: 'Descargar',
          icon: 'mdi-download',
          color: 'primary',
          condition: (item) => {
            return item.downloaded && item.taskVideoFilesList?.length > 0;
          },
          action: (item) => {
            // const urls = item.taskVideoFilesList.map((file) => {
            //   return `/api/v1/basic/record/download?key=${item.key}&dir=${file.dir}&name=${file.name}`;
            // });
            // downloadAndZipFiles(urls, `${this.device.name} - (${item.fromDate} a ${item.toDate})`);
            item.taskVideoFilesList.forEach((file) => {
              downloadFileWithAnchor(`/api/v1/basic/record/download?key=${item.key}&dir=${file.dir}&name=${file.name}`);
            });
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getTitle: (item) => item.taskId,
    getSubTitle: (item) => item.fromDate + ' - ' + item.toDate,
    getExtra: (item) => {
      if (item.error) {
        return 'Error: ' + item.error;
      }
      return taskStatusMessages[item.taskResponse?.state || '-1'] || 'Desconocido';
    },
    validateTime: (time) => {
      return moment(time, 'YYYY-MM-DD HH:mm:ss', true).isValid();
    },
    load() {
      this.loading = true;
      ApiService({
        url: `/v2/${this.model}/device/${this.device.id}`,
        method: 'get',
      })
        .then((resp) => {
          this.items = resp.reverse().map((item) => {
            if (item.taskResponse?.state) {
              item.avatarProgress = item.taskResponse?.percent;
            }
            return {
              ...item,
            };
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
