<template>
  <kalan-dialog title="Candados" height="650px">
    <v-card class="mx-auto elevation-0" height="100%">
      <kalan-list
        :loading="loading"
        :getTitle="getTitle"
        :getSubTitle="getSubTitle"
        :items="items"
        :search="search"
        :actions="listActions"
      >
      </kalan-list>
    </v-card>
  </kalan-dialog>
</template>

<script>
import ApiService from '@/util/api.service';

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    'kalan-dialog': () => import('@/components/dialog/Dialog.vue'),
    'kalan-list': () => import('@/components/modulo/List.vue'),
  },
  data() {
    console.log('device', this.device);
    const self = this;
    return {
      loading: false,
      items: this.device?.attributes?.padlocks || [],
      search: '',
      listActions: [
        {
          help: 'Abrir candado',
          icon: 'mdi-lock-open',
          color: 'primary',

          action: (item) => {
            console.log('open', item, self?.device);
            this.loading = true;
            ApiService({
              url: `/v2/sms/unlock-padlock`,
              method: 'post',
              data: {
                deviceId: self?.device?.id,
                identifier: item.identifier,
              },
            }).then(() => {
              this.loading = false;
              item.locked = false;
            }).catch(() => {
              this.loading = false;
            });
          },
        },
      ],
    };
  },
  methods: {
    getTitle(item) {
      return item.identifier;
    },
    getSubTitle(item) {
      return item.locked ? 'Cerrado' : 'Abierto';
    },
  },
};
</script>
